<template>
  <b-container fluid>
    <b-card>
      <b-overlay>
        <div class="m-2">
          <b-row>
            <b-col
                cols="8"
                md="8"
            >
              <h3>Danh sách văn bằng/ chứng chỉ của học viên</h3>
            </b-col>
                <b-col>
                    <v-select
                        v-model="credentialType"
                        :options="credentialTypeDropdownOptions"
                        :reduce="option => option.value"
                        @input="onCredentialTypeChange"
                    />
                </b-col>

                <b-col
                    class="text-left"
                >
                  <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="mb-0 mt-0 mb-sm-1"
                      @click="onSearch"
                  >
                    <feather-icon icon="FilterIcon" /> Lọc
                  </b-button>
                </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <vue-good-table
                  mode="remote"
                  class="my-table"
                  row-style-class="vgt-row"
                  style-class="vgt-table striped bordered"
                  :columns="columns"
                  :rows="credentialsOfStudent"
                  :line-numbers="true"
              >
                <div
                    slot="emptystate"
                    style="text-align: center; font-weight: bold"
                >
                  Không có bản ghi nào !
                </div>

                <template
                    slot="table-row"
                    slot-scope="props"
                >
                  <span v-if="props.column.field === 'status'">
                    {{ getStatusName(props.row.status) }}
                  </span>
                  <span v-else>{{ props.formattedRow[props.column.field] }}</span>

                </template>
              </vue-good-table>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-card>
  </b-container>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BButton,
  BButtonGroup,
  BCard,
  BCol,
  BContainer,
  BDropdown,
  BDropdownItem,
  BFormGroup, BFormSelect, BOverlay, BPagination, BRow, BModal,
  VBTooltip,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import '@core/scss/vue/libs/vue-good-table.scss'
import { getUser } from '@/auth/utils'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import CreateCredentialCondition from '@/views/certificate/CreateCredentialCondition.vue'
import vSelect from 'vue-select'

export default {
  name: 'ListCredentials',
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    CreateCredentialCondition,
    BDropdown,
    BDropdownItem,
    VueGoodTable,
    BButton,
    BButtonGroup,
    BCard,
    BCol,
    BContainer,
    BFormGroup,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    BModal,
    vSelect,
  },
  props: {},
  data() {
    return {
      user: getUser(),
      isLoading: false,
      credentialType: null,
      currentCredentialOfStudent: undefined,
      columnsCertificate: [
        {
          label: 'Tên học viên',
          field: 'name',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Ngày tháng năm sinh',
          field: 'birthday',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Nơi sinh',
          field: 'address',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Giới tính',
          field: 'gender',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Dân tộc',
          field: 'ethnic',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Tên chứng chỉ',
          field: 'credentialName',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Năm cấp',
          field: 'year',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Số hiệu chứng chỉ',
          field: 'code',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Số vào sổ gốc',
          field: 'codeCredentialStorage',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Trạng thái',
          field: 'status',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: '',
          field: 'action',
          sortable: false,
          tdClass: 'text-center',
        },
      ],
      columns: [
        {
          label: 'Tên học viên',
          field: 'name',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Ngày tháng năm sinh',
          field: 'birthday',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Nơi sinh',
          field: 'address',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Giới tính',
          field: 'gender',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Dân tộc',
          field: 'ethnic',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Quốc tịch',
          field: 'nationality',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Tên văn bằng',
          field: 'credentialName',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Năm cấp',
          field: 'year',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Xếp loại',
          field: 'graduationText',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Số hiệu văn bằng',
          field: 'code',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Số vào sổ gốc',
          field: 'codeCredentialStorage',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Trạng thái',
          field: 'status',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      types: 'credentialOfStudent/types',
      credentialsOfStudent: 'credentialOfStudent/credentialsOfStudent',
      statuses: 'credentialOfStudent/statuses',
    }),
    credentialTypeDropdownOptions() {
      return [{ label: 'Tất cả ', value: null }, ...this.types]
    },
  },
  async created() {
    await this.readStudentsCredentialStorage({
      currentPage: 1,
      itemsPerPage: 1000,
      studentId: this.user.studentId,
    })
  },
  methods: {
    ...mapActions({
      readStudentsCredentialStorage: 'credentialOfStudent/readStudentsCredentialStorage',
    }),
    getStatusName(id) {
      return this.statuses.find(status => status.value === id).label
    },
    onCredentialTypeChange(value) {
      this.credentialType = value
    },
    async onSearch() {
      await this.readStudentsCredentialStorage({
        currentPage: 1,
        itemsPerPage: 1000,
        studentId: this.user.studentId,
        credentialType: this.credentialType,
      })
    },

  },
}
</script>

<style scoped lang="scss">

</style>
